import React, { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { Typography, Popconfirm, Button, Form, Modal } from 'antd';
const { Title, Paragraph } = Typography;
import './EditorItem.less';
import { useAlert } from '@job-commander/shared';
import { useLoader } from '@surinderlohat/react-hooks'
import { useDialog } from '@surinderlohat/react-dialog';
import { StripeCard } from '../../../../jobCommander/src/components/BuyModal/StripeCard/StripeCard';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { usePaymentService } from '../../../../jobCommander/src/api/PaymentService';

type Item = {
  heading?: string;
  title?: string;
  lines: string[];
  scaffold: boolean;
  removable: boolean;
  required: boolean | Function;
  id?: number;
};

type Props = {
  item: Item;
  readOnly: boolean;
  disableRemove?: boolean;
  isEmployer?: boolean;
  onRemoveClick?: Function;
  onClick?: Function;
  isDeleteButtonRemove?: boolean;
  onJobExtend?: React.MouseEventHandler<HTMLElement>;
  isJobInFreeTrial?: boolean;
  handleUpgrade?: any;
};
// eslint-disable-next-line max-len
const EditorItem: FC<Props> = ({ item, readOnly, onClick, disableRemove, isEmployer, isDeleteButtonRemove, onRemoveClick, onJobExtend, isJobInFreeTrial }) => {
  const alert = useAlert();
  const loader = useLoader();
  const [ isCardValid, setIsCardValid ] = useState(true);
  const [ isPaymentModalVisible ,setPaymentModalVisible ] = useState(false);
  const stripe = useStripe();
  const dialog = useDialog();
  const elements = useElements();
  const paymentService = usePaymentService();

  const message = isEmployer
    ? 'Are you sure you want to delete this job? If deleted, the job will be removed from JobCommander.'
    : 'Are you sure you want to remove this item?';
  const { title, lines, scaffold, heading } = item;

  const onCardValueChange = e => {
    if (e.error != null || e.complete !== true) {
      setIsCardValid(false);
      return;
    }
    setIsCardValid(true);
  };

  const _onItemClick = (item, event) => {
    try {
      const popover = event.target.closest('.ant-popover');
      if (popover != null) {
        return;
      }
    } catch (e) {
      alert.error('EditorItem/_onItemClick', e);
      return;
    }

    if (readOnly === true) return;

    if (typeof onClick === 'function') {
      onClick(item);
    }
  };

  const _onRemoveClick = (item, e) => {
    if (readOnly === true) return;

    if (typeof onRemoveClick === 'function') {
      onRemoveClick(item);
    }
  };

  const isRequired = () => {
    if (readOnly === true) return false;

    if (typeof item.required === 'function') {
      return item.required();
    }

    return item.required;
  };


  const saveChanges = async () => {
    setPaymentModalVisible(false);
    alert.success('Thank You!');
    setTimeout(() => {
      window.location.reload();
    }, 1000); // Adjust the time delay as needed

  };

  const handleOnBuy = async (id) => {
    loader.showLoader();
    const request = { jobId: id };
    const checkoutResult = await paymentService.upgradePremiumPlan(request);

    if (checkoutResult.hasError) {
      alert.error(checkoutResult.statusText);
      loader.hideLoader();
      return;
    }

    const payload = await stripe.confirmCardPayment(checkoutResult.data?.paymentIntent.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (payload.error) {
      alert.error(payload.error.message);
      loader.hideLoader();
      return;
    }

    const result = await paymentService.confirmPayment(checkoutResult.data?.transactionId);

    if (result.hasError) {
      loader.hideLoader();
      return;
    }
    saveChanges();
    loader.hideLoader();
  };

  return (
    <div
      className={`editor-ex-item ${scaffold === true ? 'scaffold' : ''} ${readOnly === true ? 'readonly' : ''}`}
      onClick={e => _onItemClick(item, e)}
    >
      <Title level={5}>{heading ?? title}</Title>
      {readOnly && isJobInFreeTrial && lines.map((line) => (
        <Button key ={line} type="primary" className='upgradeButton' onClick={() => setPaymentModalVisible(true)}>
              Upgrade to premium
        </Button>
      ))}
      {/* //need to comment this lines for hide expiration date */}
      {isRequired() && <FontAwesomeIcon icon={faExclamationCircle} style={{ color: '#fbae17' }} />}
      { item.removable && !readOnly && !disableRemove && !isDeleteButtonRemove &&(
        <Popconfirm
          title={message}
          okText="Yes"
          cancelText="No"
          onConfirm={e => {
            e.stopPropagation();
            _onRemoveClick(item, e);
          }}
        >
          <FontAwesomeIcon icon={faMinusCircle} style={{ color: '#fbae17' }} onClick={e => e.stopPropagation()} />
        </Popconfirm>
      )}


      <Modal
        visible={isPaymentModalVisible}
        title={`Job Activation for ${item?.title}`}
        destroyOnClose={true}
        maskClosable={true}
        onOk={ () => handleOnBuy(item?.id)}
        onCancel={() => { setPaymentModalVisible(false); dialog.closeDialog() }}
        okButtonProps={{ loading: loader.isLoading }}
        cancelButtonProps={{ disabled: loader.isLoading }}
        okText="Buy"
      >
        <Form id="buy-form" layout="horizontal" >
          <Form.Item
            label="Total Payment : "
          >
            {'$199'} 
          </Form.Item>
          <Form.Item name="card">
            <StripeCard onChange={onCardValueChange} isCardValid={isCardValid} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

EditorItem.defaultProps = {
  disableRemove: false,
  // scaffold: false,
  // required: false,
  readOnly: false,
};

export { EditorItem };
