import {
  EDITOR_TYPES,
  EDUCATION_LEVEL,
  ISelectOption,
  PAY_TYPE,
  SelectOptionModel,
  Utilities,
} from '@job-commander/shared';
import { IJob } from 'src/interfaces';
import { ProductModel } from './Product.model';
import { ScaffoldModel } from './Scaffold.model';
import moment from 'moment';

export class JobModel extends ScaffoldModel implements ISelectOption {
  title: string;
  description: string;
  city: string;
  state: string;
  zipCode: string;
  industry: (string | number)[];
  vacancy: number;
  jobVenue: SelectOptionModel;
  experienceInYear: SelectOptionModel;
  workWeek: SelectOptionModel;
  benefitsWithLabels: any[];
  isRelocation: boolean;
  willingToTravel: SelectOptionModel;
  educationLevel: SelectOptionModel;
  gpa: SelectOptionModel;
  preferenceMajorIds: number[] | SelectOptionModel[];
  benefits: number[];
  payType: SelectOptionModel;
  salary: string;
  minimumCompatibilityScore: number;
  isSlam: boolean;
  isExpired: boolean;
  slamAmount: number;
  jobNumber: string;
  answersSelected: { [key: string]: string };
  expirationDate?: string;
  software?: string;
  numberQuestions?: number;
  questionnaire?: number;
  selectedPlan?: ProductModel;
  isCreatedBeforeFirstHiring?: boolean;
  copyAnswersFromJobId?: number;
  questionTemplate?: number;
  isJobInFreeTrialPeriod?: boolean;
  constructor(data?: Partial<JobModel>) {
    super(data);
    Object.assign(this, data);
    this.editorType = EDITOR_TYPES.JOB;
    // this.title = Utilities.trimCharacters(data.title, 29) || ''; commented due to show full title in UI 
    this.lines = [
      data?.expirationDate
        ? `Expires: ${moment(data?.expirationDate).format('l')}`
        : Utilities.trimCharacters(data?.description, 60) || '',
    ];
    this.jobVenue = data?.jobVenue || new SelectOptionModel();
    this.experienceInYear = data?.experienceInYear || new SelectOptionModel();
    this.workWeek = data?.workWeek || new SelectOptionModel();
    this.willingToTravel = data?.willingToTravel || new SelectOptionModel();
    this.educationLevel = data?.educationLevel || new SelectOptionModel();
    this.gpa = data?.gpa || new SelectOptionModel();
    this.payType = data?.payType || new SelectOptionModel();
  }

  public get label(): string {
    return this.title;
  }

  public get value(): string {
    return this.tempId;
  }

  public static deserialize(response: IJob): JobModel {
    return new JobModel({
      ...response,
      isNew: false,
      // isExpired: moment.utc(response?.expirationDate).diff(moment(), 'days') <= 5,
      // commented this code due to issue in expiration date renewal mail send
      experienceInYear: SelectOptionModel.deserialize(response?.experienceInYear),
      jobVenue: SelectOptionModel.deserialize(response?.jobVenue),
      workWeek: SelectOptionModel.deserialize(response?.workWeek),
      willingToTravel: SelectOptionModel.deserialize(response?.willingToTravel),
      educationLevel: SelectOptionModel.deserialize(response?.educationLevel),
      gpa: SelectOptionModel.deserialize(response?.gpa),
      payType: SelectOptionModel.deserialize(response?.payType),
      industry: response?.industry ? [ response?.industry?.id?.toString(), response?.industryJob?.id ] : [],
      benefits: response?.benefits?.map(x => x?.id) || [],
      benefitsWithLabels: response?.benefits,
      preferenceMajorIds: response?.preferenceMajors?.map(x => x?.id) || [],
      answersSelected: {},
    });
  }

  public static deserializeList(data: IJob[]): JobModel[] {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.map(x => JobModel.deserialize(x));
  }

  public serializSignup(): IJob {
    return {
      answersSelected: this.answersSelected
        ? Object.keys(this.answersSelected).map(key => ({ answerId: this.answersSelected[key] }))
        : [],
      ...this.serialize(),
    };
  }

  public serialize(): IJob {
    return {
      id: this.id,
      city: this.city,
      state: this.state,
      description: this.description,
      industryId: parseInt(this.industry[0] as string), // Need This conversion
      industryJobId: this.industry[1],
      minimumCompatibilityScore: this.minimumCompatibilityScore,
      vacancy: this.vacancy,
      payTypeId: this.payType?.value,
      salary: this.payType?.value === PAY_TYPE.NON_PAYING ? null : this.salary,
      educationLevelId: this.educationLevel?.value,
      gpaId: isNoneOrDoctorate(this.educationLevel?.value) ? null : this.gpa?.value,
      preferenceMajorIds:
        this.educationLevel?.value === EDUCATION_LEVEL.NONE
          ? []
          : this.preferenceMajorIds || this.preferenceMajorIds.map(major => parseInt(`${major.value}`)),
      isRelocation: this.isRelocation,
      isSlam: this.isSlam,
      slamAmount: this.isSlam ? Number(this.slamAmount.toFixed(2)) : null,
      title: this.title,
      willingToTravelId: this.willingToTravel?.value,
      workWeekId: this.workWeek?.value,
      experienceInYearId: this.experienceInYear?.value,
      zipCode: this.zipCode,
      jobVenueId: this.jobVenue?.value,
      benefits: this.benefits ? this.benefits.map(benefitId => benefitId) : [],
      copyAnswersFromJobId: this.copyAnswersFromJobId,
    };
  }
}

const isNoneOrDoctorate = value => [ EDUCATION_LEVEL.DOCTORATE, EDUCATION_LEVEL.NONE ].includes(value);
