/* eslint-disable max-len */
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Form, Modal } from 'antd';
import React, { FC, useState } from 'react';
import './BuyModal.less';
import { useLoader } from '@surinderlohat/react-hooks';
import { ViewPermission, useAlert } from '@job-commander/shared';
import { ProductModel } from 'src/models';
import { usePaymentService } from 'src/api/PaymentService';
import { JobModel } from 'src/models/Job.model';
import { StripeCard } from './StripeCard/StripeCard';

type Props = {
  totalNumberOfHires?: number; // number of candidates to hire
  totalNumberOfJobDiscription?: number;
  selectedPlan?: ProductModel;
  onBuySuccess: Function;
  onCancel: Function;
  allowFreeJobPost: boolean;
  jobs?: JobModel[];
};

const BuyEmployerPackage: FC<Props> = ({
  allowFreeJobPost,
  onBuySuccess,
  totalNumberOfHires,
  totalNumberOfJobDiscription,
  onCancel,
  selectedPlan,
  jobs,
}) => {
  const [ isCardValid, setIsCardValid ] = useState(true);
  const loader = useLoader(false);
  const stripe = useStripe();
  const elements = useElements();
  const alert = useAlert();
  const paymentService = usePaymentService();
  const onCardValueChange = e => {
    console.log(e, 'e')
    if (e.error != null || e.complete !== true) {
      setIsCardValid(false);
      return;
    }
    setIsCardValid(true);
  };

  const handleOnBuy = async () => {
    loader.showLoader();
    const cardElement = elements.getElement(CardElement);
  
    if (!cardElement) {
      alert.error('Please enter valid card details');
      console.log('here error');
      loader.hideLoader();
      return;
    }
  
    if (!selectedPlan) {
      alert.error('Please select subscription package');
      loader.hideLoader();
      return;
    }
  
    // Tokenize the card commented this code due to testing in DEV env with test cards
    const { token, error } = await stripe.createToken(cardElement);
  
    if (error) {
      alert.error('Please enter valid card details');
      loader.hideLoader();
      return;
    }
  
    if (token) {
      loader.showLoader();
      const employerJobs = jobs.map(job => job.serializSignup());
      const isSelectedPremium = sessionStorage.getItem('isSelectedPremium');
      const checkoutResult = await paymentService.paymentCheckoutJobs({ productId: selectedPlan.id, jobs: employerJobs, StripeCardToken : token?.id, isSelectedPremium : isSelectedPremium }).then((checkoutResult) => {
        if (checkoutResult.hasError) {
          loader.hideLoader();
          return;
        }
  
        if (checkoutResult.data.isFirstHiringDone) {
          const payload = stripe.confirmCardPayment(checkoutResult.data?.paymentIntent?.clientSecret, {
            payment_method: {
              card: elements.getElement(CardElement),
            },
          }).then((result) => { 
            if (result.error) {
              alert.error(result.error.message);
              loader.hideLoader();
              return;
            }
            return result;
          });
        }
  
        const result = paymentService.confirmPayment(checkoutResult.data?.transactionId).then((result) => {
          if (result.hasError) {
            loader.hideLoader();
            return;
          }
          loader.hideLoader();
          onBuySuccess();
        });
      });
    }
  };
  

  const onCancelButtonClick = () => {
    Modal.confirm({
      title: 'Confirm',
      content:
          // eslint-disable-next-line max-len
          'If you close this window, all your work will be lost and you\'ll have to start over. Are you sure you want to continue?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => onCancel(),
    });
  };

  return (
    <Modal
      visible={true}
      title={'Make Payment'}
      destroyOnClose={true}
      maskClosable={true}
      closable={true}
      onOk={handleOnBuy}
      onCancel={() => !loader.isLoading && onCancelButtonClick()}
      okButtonProps={{ loading: loader.isLoading }}
      cancelButtonProps={{ disabled: loader.isLoading }}
      okText={allowFreeJobPost ? 'Submit' : 'Buy'}
    >
      <div>
        <p>
          <strong>Package Name : </strong>
          {selectedPlan?.displayName}
        </p>
        <p>
          <strong>Price/Job Description : </strong>${selectedPlan?.price}
        </p>
        {/* <p>
          <strong>Valid For : </strong>
          {selectedPlan?.subscriptionDays} Days
        </p> */}
        <p>
          <strong>Total Number of Job Descriptions : </strong>
          {totalNumberOfJobDiscription}
        </p>
        <p>
          <strong>Total Payment : </strong>{' '}
          <span style={{ textDecoration: allowFreeJobPost ? 'line-through' : 'none' }}>
              ${selectedPlan?.price * totalNumberOfJobDiscription}
          </span>
          {allowFreeJobPost && <span style={{ color: 'red', marginLeft: '5px' }}>$0</span>}
        </p>
      </div>
      <Form id="buy-form" layout="horizontal" initialValues={{ quantity: 1 }}>
        <Form.Item name="card">
          <StripeCard onChange={onCardValueChange} isCardValid={isCardValid} />
        </Form.Item>
      </Form>
      
      { allowFreeJobPost ? 
        (
          <p style={{ fontSize: '11px', fontWeight: 'bolder' }}>
            <strong>Note : </strong>
          Your credit card information will be stored, and you will not be charged any fees on JobCommander Lite. 
          You can add up to 2 job descriptions on JobCommander Lite. If you decide to add additional job descriptions, you will be upgraded to JobCommander Premium, 
          and your credit card will be charged in accordance with our fees per job description in addition to any other features you choose. 
          If you opt to de-activate or remove a job description you’ve submitted, 
          you will be required to complete a short survey. Any questions? Email us at <span style={{ color: 'blue' }}> info@jobcommander.com </span> 
          </p>
        ) : (
          <p style={{ fontSize: '12px', fontWeight: 'bolder' }}>
            <strong>Note : </strong>
            With JobCommander Premium, you will be charged an activation fee based on the number of job descriptions you input. 
            You can de-activate your job descriptions at any time prior to the 30-day renewal. De-activation requires completion of a short survey. 
            Once you deactivate a job description, your job will no longer be visible to any prospective candidates. 
            If you do not de-activate or remove your job description prior to the renewal date, you will be charged a $99 per month subscription fee per job description.
          </p>
        )}
    </Modal>
  );
};

export { BuyEmployerPackage };
